import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Footer'
import disclaimerLinksSelector from '../../redux/selectors/url/disclaimerLinksSelector'

type StateProps = Pick<Props, 'disclaimerLinks'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    disclaimerLinks: disclaimerLinksSelector(state),
})

export default connect(mapStateToProps)
