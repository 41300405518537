import { createSelector } from 'reselect'
import entryDataSelector from '../entryDataSelector'
import DisclaimerLink from '../../../entities/DisclaimerLink'

const disclaimerLinksSelector = createSelector(
    entryDataSelector,
    (entryData): DisclaimerLink[] => entryData.links,
)

export default disclaimerLinksSelector
