import { createSelector } from 'reselect'
import entryDataSelector from '../entryDataSelector'

const abortUrlSelector = createSelector(
    entryDataSelector,
    // TODO: clarification regarding the abort url
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (entryData): string | undefined => undefined,
)

export default abortUrlSelector
