import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './productSecurityButton.css'
import AdditionalInformation from '../../entities/AdditionalInformation'

export type Props = {
    productSecurityInfo: AdditionalInformation
    isVisible: boolean
}

const ProductSecurityButton: FunctionComponent<Props> = (props) => {
    const {productSecurityInfo, isVisible} = props
    const {t} = useTranslation()

    if (!isVisible) {
        return null
    }

    return (
        <div className={style.container}>
            <a
                className={style.linkButton}
                href={productSecurityInfo?.value}
                target="_blank"
                rel="noopener noreferrer"
            >
                {t(`additionalInformation.${productSecurityInfo?.key}`)}
            </a>
        </div>
    )
}

export default ProductSecurityButton
