import { createSelector } from 'reselect'
import summaryAdditionalInfoSelector from './summaryAdditionalInfoSelector'
import AdditionalInformation from '../../entities/AdditionalInformation'

const productSecurityInfoSelector = createSelector(
    summaryAdditionalInfoSelector,
    (summaryAdditionalInfo): AdditionalInformation =>
        summaryAdditionalInfo?.find((info) => info.key === 'productSecurity') || null,
)

export default productSecurityInfoSelector
