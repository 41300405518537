import { createSelector } from 'reselect'
import summarySelector from './summarySelector'
import AdditionalInformation from '../../entities/AdditionalInformation'

const summaryAdditionalInfoSelector = createSelector(
    summarySelector,
    (summary): AdditionalInformation[] => summary.additionalInformation,
)

export default summaryAdditionalInfoSelector
