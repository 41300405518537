import { connect, MapStateToProps } from 'react-redux'
import { Props } from './ProductSecurityButton'
import productSecurityInfoSelector from '../../redux/selectors/productSecurityInfoSelector'
import modeIsFktSelector from '../../redux/selectors/feature-scope/modelIsFktSelector'

type StateProps = Pick<Props, 'productSecurityInfo' | 'isVisible'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    productSecurityInfo: productSecurityInfoSelector(state),
    isVisible: !modeIsFktSelector(state),
})

export default connect(mapStateToProps)
