import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './footer.css'
import DisclaimerLink from '../../entities/DisclaimerLink'

export type Props = {
    disclaimerLinks: DisclaimerLink[]
}

const Footer: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation()
    const {disclaimerLinks} = props

    const disclaimerLinkElements = disclaimerLinks?.map((disclaimerLink: DisclaimerLink) => (
        <li key={disclaimerLink.id} className={style.disclaimerLinkListItem}>
            <a href={disclaimerLink.value}
               target="_blank"
               rel="noopener noreferrer"
            >
                {t(`footer.${disclaimerLink.id}`)}
            </a>
        </li>
    ))

    return (
        <footer className={style.footer}>
            <ul className={style.disclaimerLinkList}>
                {disclaimerLinkElements}
            </ul>
        </footer>
    )
}

export default Footer
