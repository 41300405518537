import { createReducer } from 'typesafe-actions'
import SummaryData from '../../entities/SummaryData'
import { setSummaryState, resetSummaryState } from '../actions/app/summary.actions'

export type SummaryState = SummaryData

export const defaultState: SummaryState = {
    informations: [],
    remarks: [],
    repurchaseExcluded: false,
    generalInformation: [],
    additionalInformation: [],
}

const summaryReducer = createReducer(defaultState)
    .handleAction(setSummaryState, (state, action) => action.payload)
    .handleAction(resetSummaryState, () => defaultState)

export default summaryReducer
